import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/gestione_familiare.jpg';
import pic2 from '../assets/images/ombrelloni_cesenatico.jpg';
import pic3 from '../assets/images/food_drink.jpg';

import config from '../../config';
import {useIntl} from "gatsby-plugin-intl"

const IndexPage = () => {
    const intl = useIntl()
    return (
        <Layout>

            <section id="banner">
                <div className="inner">
                    <h2>{config.heading}</h2>
                    <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "subheading"})}}/>
                </div>
            </section>

            <section id="wrapper">
                <section id="one" className="wrapper spotlight style1">
                    <div className="inner">
                        <a href="/#" className="image">
                            <img src={pic1} alt=""/>
                        </a>
                        <div className="content">
                            <h2 className="major"
                                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "h2_storia"})}}/>
                            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "p_storia"})}}/>
                        </div>
                    </div>
                </section>

                <section id="two" className="wrapper alt spotlight style2">
                    <div className="inner">
                        <a href="/#" className="image">
                            <img src={pic2} alt=""/>
                        </a>
                        <div className="content">
                            <h2 className="major"
                                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "h2_spiaggia"})}}/>
                            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "p_spiaggia"})}}/>
                        </div>
                    </div>
                </section>

                <section id="three" className="wrapper spotlight style3">
                    <div className="inner">
                        <a href="/#" className="image">
                            <img src={pic3} alt=""/>
                        </a>
                        <div className="content">
                            <h2 className="major"
                                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "h2_ristorante"})}}/>
                            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "p_ristorante"})}}/>
                        </div>
                    </div>
                </section>

                <section id="four" className="wrapper alt style1 no-margin-bottom">
                    <div className="inner">
                        <h2 className="major color-black"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "servizi"})}}/>

                        <section className="features">
                            <article>
                                <h3 className="major"
                                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_t_cani"})}}/>
                                <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_p_cani"})}}/>
                            </article>
                            <article>
                                <h3 className="major"
                                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_t_wifi"})}}/>
                                <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_p_wifi"})}}/>
                            </article>
                            <article>
                                <h3 className="major"
                                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_t_relax"})}}/>
                                <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_p_relax"})}}/>
                            </article>
                            <article>
                                <h3 className="major"
                                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_t_disabili"})}}/>
                                <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_p_disabili"})}}/>
                            </article>
                            <article>
                                <h3 className="major"
                                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_t_bambini"})}}/>
                                <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_p_bambini"})}}/>
                            </article>
                            <article>
                                <h3 className="major"
                                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_t_sport"})}}/>
                                <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "s_p_sport"})}}/>
                            </article>
                        </section>
                    </div>
                </section>
                <section id="four" className="wrapper alt style1 no-margin">
                    <div className="inner">
                        <div className="elfsight-app-dcd3656a-219a-4b4b-8374-1d665cf3b1ca"
                             class="elfsight-app-dcd3656a-219a-4b4b-8374-1d665cf3b1ca"></div>
                    </div>
                </section>
            </section>
        </Layout>
    )
};

export default IndexPage;
